@font-face {
	font-display: swap;
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/barlow-condensed-v12-latin-regular.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/barlow-condensed-v12-latin-600.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/barlow-condensed-v12-latin-700.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/barlow-condensed-v12-latin-800.woff2') format('woff2');
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
	--color-text: white;
	--color-bg: black;
	--color-text-alt: red;
	--color-link: red;
	--color-link-hover: white;
	/* Column/item variables */
	--ratio: .7;
	/* image ratio */
	--grid-item-width: 32vw;
	/* image width */
	--justify: space-between;
	/* spread columns or center */
	--gap: 2.5vw;
	/* column gap and margin for items */
	--rotation: 0deg;
	/* section rotation */
	--radius: 0px;
	/* image border radius */
	--offset: -30vh;
	/* offset of even columns */
	/* content section */
	--radius-small: 4px;
	/* border radius of small images */
}

body {
	margin: 0;
	text-transform: uppercase;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: 'Barlow Condensed';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

.frame {
	width: 100%;
	line-height: 1.2;
	font-size: 1rem;
	color: var(--color-title);
	padding: 1rem;
	display: grid;
	grid-template-columns: auto auto;
	grid-template-areas: 'title prev' 'nav nav' 'sponsor sponsor';
	grid-gap: 1rem;
	z-index: 100;
	position: relative;
	background: var(--color-bg);
}

.frame a:not(.frame__title-back),
.row+p a, .footer a {
	white-space: nowrap;
	overflow: hidden;
	position: relative;
}

.frame a:not(.frame__title-back)::before,
.row+p a::before, .footer a::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 90%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
}

.frame a:not(.frame__title-back):hover::before,
.row+p a:hover::before, .footer a::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
}

.frame__title {
	grid-area: title;
	display: flex;
	white-space: nowrap;
}

.frame__title-main {
	font-size: 1rem;
	margin: 0;
	font-weight: normal;
}

.frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
}

.frame__title-back span {
	display: none;
}

.frame__title-back svg {
	fill: currentColor;
}

.frame__prev {
	justify-self: end;
	grid-area: prev;
}


.language__selector-title {
	font-weight: 400;
	margin: 0;
	font-size: 1rem;
	width: 100%;
	color: var(--color-text-alt);
}

.language__selector-item {
	grid-row: 2;
}

.section {
	line-height: 1;
	position: relative;
	top: 0;
	z-index: 4;
	width: 100%;
	min-height: 60vh;
	background: var(--color-bg);
}

.section--intro {
	margin-bottom: 250vh;
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	justify-content: center;
	padding: 0 6vw 0 6vw;
	cursor: default;
}

.section__title {
	width: 100%;
	font-family: 'Barlow Condensed';
	font-weight: 700;
	text-transform: uppercase;
	font-size: clamp(3.125rem, 1.7597rem + 4.3689vw, 8.75rem);
	margin: 5vh 0 6vh;
	line-height: 1;
	-moz-font-feature-settings: "swsh";
	-webkit-font-feature-settings: "swsh";
	font-feature-settings: "swsh";
	hyphens: auto;
	max-width: 50vw;
}

.section__title--medium {
	font-size: clamp(1.5rem, 12vw, 9rem);
}

.section__text {
	margin: 0;
	max-width: 400px;
}

.section__text--alt {
	margin-top: auto;
	color: var(--color-text-alt);
}

.section__arrow {
	transform: translateY(3px) rotate(135deg);
	fill: currentColor;
}

.section--columns {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	width: 100%;
	height: 100vh;
}

.columns {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: var(--justify);
	gap: var(--gap);
	height: 100%;
	transform: rotate(var(--rotation));
	align-items: center;
	will-change: transform, opacity;
}

.column-wrap {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	padding: 5vh 0 15vh;
	will-change: transform;
}

.column-wrap:nth-child(even) {
	margin-top: var(--offset);
}

.column {
	position: relative;
	display: block;
	will-change: transform;
}

.column.left {
	padding-top: 30vh !important;
}

.column__item {
	--grid-item-height: calc(var(--grid-item-width) * var(--ratio));
	width: var(--grid-item-width);
	height: var(--grid-item-height);
	position: relative;
	overflow: hidden;
	border-radius: var(--radius);
	cursor: pointer;
	margin: 0 0 var(--gap) 0;
	z-index: 1;
	will-change: transform, filter;
}


.column__item-img {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	will-change: transform;
	border-radius: 20px;
}

.section--benefits {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: left;
	justify-content: center;
	padding: 8rem 5vw 10vh;
	cursor: default;
}

.section--product {
	display: flex;
	flex-direction: column;
	align-items: left;
	text-align: left;
	justify-content: center;
	padding: 8rem 5vw 10vh;
	cursor: default;
}


.section--product h2, .section--product h3 {
width: 75%;
}


.footer {
	width: 100%;
	margin-top: 8rem;
}

.logo__footer {
	width: 220px;
	height: auto;
	margin-bottom: 2rem;
}

.wrapper__footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	font-size: 20px;
}

.footer .imprint {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 40px;
}

.footer .copyright {
	display: flex;
	flex-direction: column;
}

.section--benefits h2 {}

.section__header {
	display: flex;
	border-bottom: 1px solid #000;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	padding: 4vh 8vw;
	margin-bottom: 4rem;
}

.section__year {
	color: var(--color-text-alt);
}

.row {
	text-align: left;
	width: 100%;
}

.row__header {
	width: 100%;
	display: grid;
	grid-template-columns: 4ch 1fr;
	grid-template-rows: auto auto;
	grid-gap: 2rem;
	justify-content: start;
	padding: 6rem 0 3rem;
}

.row__header span:last-child {
	grid-column: 1 / span 2;
}

.row__content {
	display: grid;
	width: 100%;
	grid-auto-flow: column;
	grid-gap: 1rem;
	justify-content: start;
}

.row__content-imgwrap {
	overflow: hidden;
	position: relative;
	border-radius: var(--radius-small);
}

.row__content-img {
	aspect-ratio: 2;
	height: 100px;
	background-size: cover;
	background-position: 50% 50%;
}

.row__content-imgwrap:nth-child(even) .row__content-img {
	aspect-ratio: 1;
}

.row__content-imgwrap:nth-child(4n) .row__content-img {
	aspect-ratio: 1.5;
}

.row__content-imgwrap:last-child {
	justify-content: end;
}

.row__content-imgwrap:last-child .row__content-img {
	aspect-ratio: 2.75;
}

.row+p {
	margin-top: 40vh;
}

.logo__left img {
	width: 400px;
	height: auto;
}

.red {
	color: var(--color-text-alt);
}

h2 {
	font-size: 100px;
	line-height: 85%;
	border-left: 3px solid red;
	padding: 25px;
	margin: 0;
	-moz-font-feature-settings: "swsh";
-webkit-font-feature-settings: "swsh";
font-feature-settings: "swsh";
-webkit-hyphens: auto;
hyphens: auto;
}

h3 {
	font-size: 2.5rem;
	-moz-font-feature-settings: "swsh";
-webkit-font-feature-settings: "swsh";
font-feature-settings: "swsh";
-webkit-hyphens: auto;
hyphens: auto;
}

.box h3 {
	font-size: 2rem;
}

.wrapper {
	min-height: 100vh;
	height: auto;
	display: flex;
	overflow: visible;
	background-color: transparent;
}

.column {
	width: 50%;
}

.left h3 {
	width: 85%;
}

.right {
	background-color: transparent;
	padding: 0;
}

.boxes-container {
	display: flex;
	flex-direction: column;
	align-items:end;
}

.box {
	width: 80%;
	height: 350px;
	background-color: red;
	margin-bottom: 50px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 3rem;
	position: relative;
}

.box .icon {
	width: 80px;
	height: auto;
}

.box .icon svg {
	width: 100%;
	height: auto;
}

.box:last-child {
	margin-bottom: 0;
}

.proto img {
	width: 100%;
	height: auto;
	max-width: 50%;
	border-radius: 20px;
}

#label {
	position:absolute;
	right: 0;
	top: 0;
}

.madeinaustria {
width:40vw;
height: auto;
opacity: .2;
	
}

.mask img {
    height: 80vh;
    width: 100%;
    transform-origin: left;
    object-fit: cover;
	border-radius:20px;
}


.mask {
    visibility: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.section--fullimage {
	padding: 10vh;
}

a.language__current {
	color:white;
}

.language__selector {
	display: flex;
	gap: 40px;
}

@media only screen 
   and (max-width : 767px) {
   .frame {
	display: flex;
	flex-direction: column-reverse;
	padding: 4vh 6vw;
   }
   .logo__left img {
	width: 300px;
   }
   .logo__left {
	margin-top: 20px;
   }
   .language__selector {
	display: flex;
	gap: 20px;
	}
	.section--benefits .section.wrapper {
		display:flex;
		flex-direction: column;
	}
	.column.left, .column.right {
		padding-top: 0 !important;
		width: 100%;
	}
	h2 {
		font-size: 80px;
	}
	.boxes-container {
		flex-direction: column;
		align-items: start;
		display: flex;
		gap: 40px;
	}
	.box {
		width: 100%;
		height: 100%;
		margin-bottom: 0;
		padding: 2rem;
	}
	.section--product h2, .section--product h3 {
		width: 100%;
	}
	.section__title {
		width: 100%;
		max-width: 100%;
		font-size: 80px;
	}
	.section--intro {
		text-align: left;
		cursor: default;
		flex-direction: column;
		justify-content: start;
		align-items: flex-start;
		height: 100%;
		margin-bottom: 50vh;
		padding: 0 6vw;
		display: flex;
		overflow:hidden;
	}
	#label {
		display: none;
	}
	.footer .imprint {
		flex-direction: row;
		justify-content: flex-start;
		gap: 20px;
		display: flex;
		margin-top: 20px;
	}
	.wrapper__footer {
		flex-direction: column;
		width: 100%;
		font-size: 20px;
		display: flex;
	}
	.section--fullimage {
		padding: 0;
	}
	.left h3 {
		width: 100%;
		hyphens: none;
		-moz-hyphens: none;
		-webkit-hyphens: none;
	}
	.mask img {
		transform-origin: 0;
		object-fit: cover;
		object-position: center bottom;
		border-radius: 0;
		width: 100%;
		height: 80vh;
	}
	.section--columns {
		scale: 1.8 !important;
	}
	.column-wrap:nth-child(2n) {
		margin-top: 0 !important;
	}
		
}

@media only screen 
   and (max-width: 1199px) and (min-width: 768px) {
	.section__title {
		width: 100%;
		max-width: 100vw;
		font-size: 100px;
	}
	.frame {
		color: var(--color-title);
		grid-gap: 1rem;
		z-index: 100;
		background: var(--color-bg);
		grid-template-columns: auto auto;
		grid-template-areas: "title prev"
		"nav nav"
		"sponsor sponsor";
		width: 100%;
		padding: 6vw;
		font-size: 1rem;
		line-height: 1.2;
		display: grid;
		position: relative;
	}
	.section--intro {

		overflow:hidden;
	}
	#label {
		width: 70vw;
		top: 0;
		right: -200px;
	}
	.madeinaustria {
		width: 100%;
	}
	.section--columns {
		scale: 1.8 !important;
	}
	.column.left {
		padding-top: 15vh !important;
	}
	.section--fullimage {
		padding: 3vh;
	}

}

@media only screen 
   and (min-width : 1200px) {
	
}

@media only screen 
   and (min-width : 1600px) {
   
}

@media screen and (min-width: 53em) {
	.frame {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6vw;
		justify-content: space-between;
		align-items: center;
		justify-items: start;
		background: var(--color-bg);
	}

	.frame__prev {
		justify-self: start;
	}

	.language__selector {
		text-align: center;
		/* justify-self: center; */
		gap: 40px;
		font-weight: 700;
		display: flex;
	}

	.section,
	.row {
		font-size: 2.5rem;
		font-weight: 700;
	}

	.row__header {
		display: grid;
		grid-template-columns: 4ch auto 1fr;
		grid-template-rows: auto;
	}

	.row__header span:last-child {
		justify-self: end;
		grid-column: revert;
	}


}